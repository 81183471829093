import React, { useState } from 'react';
import logo from './logo.svg';
import {Link} from "react-router-dom";

const Header = (props) => {
   const [popup, setPopup] = useState(false);


   const aggiorna = (evt) => {
      if (popup)
         setPopup(prePopup => false);
      else
         setPopup(prePopup => true);
   }

   return (
      <div>
         {popup && ( // Il problema era dato proprio da <ClickAwayListener onClickAway={() => setPopup(false)}></ClickAwayListener>
            <div className="menu">
               <li ><Link to="/">Home</Link></li>
               <li ><Link to="/Storico"> Storico</Link></li>
               <li ><Link to="/InserisciAllenamento">Inserisci allenamento </Link></li>
               <li ><Link to="/SchedaAllenamento">Inserisci scheda di allenamento </Link></li>
               <li ><Link to="/ProgrammazioneAllenamento">Programmazione allenamento </Link></li>
               <li ><Link to="/DataAnalytics">Data Analytics </Link></li>
               <li ><Link to="/Dieta">Dieta </Link></li>
            </div>
         )}
         <img src={logo} className="App-logo" alt="logo" ></img>
         <p />
         <h1 className="header-text" onClick={aggiorna}> {props.nome} </h1>
      </div>
   );
}

export default Header;
