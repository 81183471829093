import React from 'react';
import Header from './Header';

export class DataAnalytics extends React.Component {
    constructor() {
        super();
    }

    render() {

        return (
            <>
                <Header nome="DATA ANALYTICS"/>
            </>
        );
    }
}