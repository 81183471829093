import React from 'react';
import Rectangle from 'react-rectangle';
import Header from './Header';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  } from 'chart.js';
import { Pie, Radar, Bar } from 'react-chartjs-2';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );


export const data2 = {
    labels: ['12-13', '14-15', '16-17', '18-20', '21-35', '35-...'],
    datasets: [
        {
            label: 'Numero di atleti',
            data: [2, 9, 3, 5, 2, 3],
            backgroundColor: 'rgba(255, 99, 132, 1)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        },
    ],

};

export const data1 = {
    labels: ['Thing 1', 'Thing 2', 'Thing 3', 'Thing 4', 'Thing 5', 'Thing 6'],
    datasets: [
        {
            label: '# of Votes',
            data: [2, 9, 3, 5, 2, 3],
            backgroundColor: 'rgba(255, 99, 132, 1)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        },
    ],

};

const data = {
    labels: ['Donne', 'Uomini'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19],
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',

            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

class Dashboard extends React.Component {
    constructor() {
        super();
    }



    render() {
        return (
            <>
                <Header nome="DASHBOARD"/>
                <div className="dashboard-body">

                    <Rectangle className="dashboard-rectangle-1" aspectratio={[1, 1]}>
                        <Pie data={data} width ={"1000%"} options={{ responsive: true, maintainAspectRatio: false }} />
                    </Rectangle>

                    <Rectangle className="dashboard-rectangle-2" aspectratio={[1, 1]} key='2'>
                        <Radar data={data1} options={{ responsive: true, maintainAspectRatio: false }} />
                    </Rectangle>

                    <Rectangle className="dashboard-rectangle-3" aspectratio={[1, 1]} key='3'>
                        <Bar data={data2} options={{responsive:true, maintainAspectRatio:false}}/>
                    </Rectangle>

                    <Rectangle className="dashboard-rectangle-4" aspectratio={[1, 1]} key='4'>
                        <h1>Hello4</h1>
                    </Rectangle>

                    <Rectangle className="dashboard-rectangle-5" aspectratio={[1, 1]} key='5'>
                        <h1>Hello5</h1>
                    </Rectangle>

                </div>
            </>
        );

    }

}

export default Dashboard;