import React, { useState } from 'react';
import Rectangle from 'react-rectangle';


function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

function getMonthName(numeroMese) {
  const monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
  return monthNames[numeroMese];
}

function QuadratoCalendario(props) {
  const [visibile, setVisibile] = React.useState(false);

  const showPanel = (evt) => {
    setVisibile(preVisibile => true);
  }

  return (
    <Rectangle className="rettangolo" aspectratio={[1, 1]}>
      
      <h1 className="giornoSettimanale"> {props.num} </h1>
      {true && (
        <button className="rettangolino" aspectratio={[1, 1]} onClick={showPanel}>
        </button>
      )}
      {visibile && (<div> HELLO </div>)}
    </Rectangle>
  );
}

class Calendario extends React.Component {

  renderQuadrato(i) {
    return (
      <QuadratoCalendario key={i} />
    );
  }

  constructor() {
    super();
    this.state = { currentMonth: new Date().getMonth(), currentYear: new Date().getFullYear() };
    console.log("Hello 2");
    //Le date dovranno essere passate dal campo che permette di cambiare mese (e di conseguenza anno)
  }

  diminuisciData = (evt) => {
    if (this.state.currentMonth === 0)
      this.setState({ currentMonth: 11, currentYear: this.state.currentYear - 1 });
    else
      this.setState({ currentMonth: this.state.currentMonth - 1 });

  }

  aumentaData = (evt) => {
    if (this.state.currentMonth === 11)
      this.setState({ currentMonth: 0, currentYear: this.state.currentYear + 1 });

    else
      this.setState({ currentMonth: this.state.currentMonth + 1 });
  }



  render() {
    console.log("Hello 4");
    //const {currentMonth, currentYear} = this.state;
    //const numeroMesi= getDaysInMonth(currentYear, currentMonth);
    //const nomeMese=getMonthName(currentMonth-1);
    return (
      <div>
        <button className="sinistra-button" onClick={this.diminuisciData} />
        <h1 className="mese-indicativo"> {getMonthName(this.state.currentMonth)} {this.state.currentYear}</h1>
        <button className="destra-button" onClick={this.aumentaData} />

        <p />
        <div className="board-row">
          <QuadratoCalendario className="1" num="1" />
          <QuadratoCalendario className="2" num="2" />
          <QuadratoCalendario className="3" num="3" />
          <QuadratoCalendario className="4" num="4" />
          <QuadratoCalendario className="5" num="5" />
          <QuadratoCalendario className="6" num="6" />
          <QuadratoCalendario className="7" num="7" />
        </div>
        <div className="board-row">
          <QuadratoCalendario className="8" num="8" />
          <QuadratoCalendario className="9" num="9" />
          <QuadratoCalendario className="10" num="10" />
          <QuadratoCalendario className="11" num="11" />
          <QuadratoCalendario className="12" num="12" />
          <QuadratoCalendario className="13" num="13" />
          <QuadratoCalendario className="14" num="14" />
        </div>
        <div className="board-row">
          <QuadratoCalendario className="15" num="15" />
          <QuadratoCalendario className="16" num="16" />
          <QuadratoCalendario className="17" num="17" />
          <QuadratoCalendario className="18" num="18" />
          <QuadratoCalendario className="19" num="19" />
          <QuadratoCalendario className="20" num="20" />
          <QuadratoCalendario className="21" num="21" />
        </div>
        <div className="board-row">
          <QuadratoCalendario className="22" num="22" />
          <QuadratoCalendario className="23" num="23" />
          <QuadratoCalendario className="24" num="24" />
          <QuadratoCalendario className="25" num="25" />
          <QuadratoCalendario className="26" num="26" />
          <QuadratoCalendario className="27" num="27" />
          <QuadratoCalendario className="28" num="28" />
        </div>
        <div className="board-row">
          {getDaysInMonth(this.state.currentYear, this.state.currentMonth + 1) >= 29 && (
            <QuadratoCalendario className="29" num="29" />
          )}
          {getDaysInMonth(this.state.currentYear, this.state.currentMonth + 1) >= 30 && (
            <QuadratoCalendario className="30" num="30" />
          )}
          {getDaysInMonth(this.state.currentYear, this.state.currentMonth + 1) >= 31 && (
            <QuadratoCalendario className="31" num="31" />
          )}
        </div>
        <div>
          <noscript> Il mese di {getMonthName(this.state.currentMonth)} {this.state.currentYear} contiene  {getDaysInMonth(this.state.currentYear, this.state.currentMonth + 1)} giorni </noscript>
        </div>
      </div>
    );
  }
}
export default Calendario;
