import React from 'react';
import Header from './Header';


export class ProgrammazioneAllenamento extends React.Component {
    constructor() {
        super();
    }

    render() {

        return (
            <>
                <Header nome="PROGRAMMAZIONE STAGIONALE"/>
            </>
        );
    }
}