import React from 'react';
import './App.css';
import Header from './Header';
import Calendario from './Calendario';
import Dashboard from './Dashboard';
import {Routes, Route, Link } from "react-router-dom";
import { InserisciAllenamento } from './InserisciAllenamento';
import { SchedaAllenamento } from './SchedaAllenamento';
import { ProgrammazioneAllenamento } from './ProgrammazioneAllenamento';
import { DataAnalytics } from './DataAnalytics';
import { Dieta } from './Dieta';


class App extends React.Component {
  render() {
    console.log("Hello 1");
    return (
        <div>
          
          <Routes>
            <Route exact path="/" element={<Dashboard/>}>
            </Route>
            <Route exact path="/Storico" element={<Storico/>}>
            </Route>
            <Route exact path="/InserisciAllenamento" element={<InserisciAllenamento/>}>
            </Route>
            <Route exact path="/SchedaAllenamento" element={<SchedaAllenamento/>}>
            </Route>
            <Route exact path="/ProgrammazioneAllenamento" element={<ProgrammazioneAllenamento/>}>
            </Route>
            <Route exact path="/DataAnalytics" element={<DataAnalytics/>}>
            </Route>
            <Route exact path="/Dieta" element={<Dieta/>}>
            </Route>  
          </Routes>
        </div>
    );
  }
}
export default App;

function Storico() {
  return (
    <>
      <Header nome="STORICO"/>
      <div className="calendario-body"> <Calendario className="calendario" /></div>
    </>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}
