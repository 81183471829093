import React from 'react';
import Header from './Header';

export class Dieta extends React.Component {
    constructor() {
        super();
    }

    render() {

        return (
            <>
                <Header nome="DIETA"/>
            </>
        );
    }
}