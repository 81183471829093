import React from 'react';
import Header from './Header';

export class SchedaAllenamento extends React.Component {
    constructor() {
        super();
    }

    render() {

        return (
            <>
                <Header nome="SCHEDA DI ALLENAMENTO"/>
            </>
        );
    }
}